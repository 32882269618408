import { FaBook } from "react-icons/fa";
import {Card} from 'flowbite-react';

const Curriculum = (props) => {
    return (
        <div className="content-wrapper mt-16 pt-1 md:ml-16 md:mr-5" ref={props.curriculumRef}>

      
                <div>
                   
                    {/* <div className="text-xl font-normal  text-gray-800 mb-5">
                        <strong><h2 className="flex flex-row"><FaBook size={25} className="mr-1 "/> Curriculum</h2></strong>
                    </div> */}

                    <Card className="mb-5">
                        <h4 className="text-lg  tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                        <FaBook size={20} className="mr-1 mt-1"/> Embedded computing systems &amp; robotics
                        </h4>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                        Design embedded programming systems, build networks of systems and wireless communication. Design with microcontrollers, compilers, assembly languages, signal processing, sensor networks and robotics.
                        </p>
                    </Card>

                    <Card className="mb-5">
                        <h4 className="text-lg   tracking-tight text-gray-800 dark:text-white flex flex-row md:font-normal">
                        <FaBook size={20} className="mr-1 mt-1"/> Software development & operations
                        </h4>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                        Analyze and improve programming techniques, data structures, increase dependability and automate software systems. Develop software and optimize application performance. Implement microservices, cloud computing, cloud architecture and infrastructure in DevOps Engineering.
                        </p>
                    </Card>

                    <Card className="mb-5">
                        <h4 className="text-lg  tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                        <FaBook size={20} className="mr-1 mt-1"/>Hardware designs &amp; architecture
                        </h4>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                        Design computing for large scale data analyses and complex simulations. Implement parallel programming, and advanced processor architectures. Develop digital design using hardware description language like VHDL or Verilog. Design and synthesis custom IP packaging.
                        </p>
                    </Card>

                    <Card className="mb-5">
                        <h4 className="text-lg   tracking-tight text-gray-800 dark:text-white flex flex-row md:font-normal">
                        <FaBook size={20} className="mr-1 mt-1"/> Data analysis & engineering
                        </h4>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                        Inspect, cleanse, transform, analyze and model data. Develop and automate data processing pipeline. Apply mathematical statistics and probability, develop machine learning models to perform predictions, classifications, forecastings and improvements.
                        </p>
                    </Card>

                </div>
               
        </div>
    );
};
    
export default Curriculum;