import TransImage from '../../img/trans.jpg';

           
const PIAgencyExp = (props) => {
    return (

        <div className="content-wrapper -ml-4">
             {/* <Button color="light" pill={true} size="sm" onClick={()=>{props.setContent("Experience")}} className="mb-5 -ml-4 hover:text-pink-500">
                Back
                <HiArrowNarrowLeft className="ml-2 h-3 w-3" />
            </Button> */}
        <div>
        <p className="">Princeton, NJ</p>
        <div className="group my-2">
            <a href="https://transamerica.com/"  target="_blank" rel="noopener noreferrer" >
                <img src={TransImage} className="rounded-xl w-12" alt="transamericaLogo"></img>
            </a>
            <span className="job-tooltip md:group-hover:scale-100 z-50">
                Presidential Insurance Agency
            </span>
        </div>
        </div>
       
        <h2 className="text-md mb-1 font-semibold">Business and Strategy Team</h2>
        <ul className="list-disc ml-4">

        </ul>
        <p className=""><span className="underline font-bold">Utilized:</span> Microsoft Office, Communication Skills, Organizational Skills, Management Skills, Illustration Skills, Data Analytics</p>
        </div>

       
    );
};



export default PIAgencyExp;