import { FaRegSun,FaCode } from "react-icons/fa";
import {Card} from 'flowbite-react';

const Skills = (props) => {
    return (
        <div className="content-wrapper mt-16 mb-5 pt-1 md:ml-16 md:mr-5 " ref={props.skillsRef}>

            <div className="-mt-7">
                
                <div className="ml-4 md:ml-1">
                    <h4 className="text-md text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                        Concentration Track:
                    </h4>
                    
                    </div>
                </div>

                <div className="ml-0 mt-2 flex flex-row  text-center md:justify-start">

                    <div className="md:flex md:flex-row ml-5">

                        <div className="ml-5 md:ml-3 mb-5 md:mr-4">
                            <h4 className="text-xs md:text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal  mb-2">
                            <FaRegSun size={12} className="mr-1 md:mt-2"/> Front-end
                            </h4>
                            <div className="radial-progress bg-teal-100 bg-opacity-40 text-teal-400 border-3 border-teal-400 dark:text-accent dark:border-white " style={{ "--value": "80", "--size": "4.5rem", "--thickness": "3.5px" }}>80%
                            </div>
                        </div>

                        <div className="ml-5 md:ml-16">
                            <h4 className="text-xs md:text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal  mb-2">
                            <FaRegSun size={12} className="mr-1  md:mt-2"/> Back-end
                            </h4>
                            <div className="radial-progress bg-teal-100 bg-opacity-40 text-teal-400 border-3 border-teal-400 dark:text-accent" style={{ "--value": "90", "--size": "4.5rem", "--thickness": "3.5px" }}>90%
                            </div>
                        </div>
                    </div>

                    <div className="md:flex md:flex-row md:ml-1 ml-8">

                        <div className="ml-5 md:ml-16  mb-5">
                            <h4 className="text-xs md:text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal  mb-2">
                            <FaRegSun size={12} className="mr-1  md:mt-2"/>Cloud & DevOps
                            </h4>
                            <div className="radial-progress bg-teal-100 bg-opacity-40 text-teal-400 border-3 border-teal-400 dark:text-accent" style={{ "--value": "85", "--size": "4.5rem", "--thickness": "3.5px"}}>85%
                            </div>
                        </div>

                        <div className="ml-5 md:ml-16">
                            <h4 className="text-xs md:text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal  mb-2">
                            <FaRegSun size={12} className="mr-1  md:mt-2"/>Data & AI/ML
                            </h4>
                            <div className="radial-progress bg-teal-100 bg-opacity-40 text-teal-400 border-3 border-teal-400 dark:text-accent"  style={{ "--value": "75", "--size": "4.5rem", "--thickness": "3.5px" }}>75%
                        </div>
                    </div>

                </div>

            </div>
          

                {/* <div className="text-xl font-bold  text-gray-800 mb-5">
                    <strong><h2 className="flex flex-row"><FaCode size={25} className="mr-1 "/> Technical Skills</h2></strong>
                </div> */}

            <Card className="mb-5 mt-5">
                <h4 className="text-lg  tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                <FaCode size={15} className="mr-1 mt-1"/> Proficient:
                </h4>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                Python, C, Javascript, C++, HTML, VHDL
                </p>
                {/* <progress className="progress progress-accent" value="95" max="100"></progress> */}
                {/* <progress className="progress " ></progress> */}
            </Card>

            <Card className="mb-5">
                <h4 className="text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                <FaCode size={15} className="mr-1 mt-1"/> Familiar:
                </h4>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                C#, Java, Bash, MATLAB, Go, MySQL, CSS, Verilog, VLSI, Arduino, TI-MSP432, RISC-V
                </p>
        
            </Card>

            <Card className="mb-5">
                <h4 className="text-lg  tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                <FaCode size={15} className="mr-1 mt-1"/> Software Engineering Stack:
                </h4>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                AWS, React.js, Node.js, Express.js, Git, Docker, Flask, Streamlit, MongoDB, PostgresQL, Prometheus, Grafana, Kubernetes, Terraform, GitLab, GitHub, Bitbucket, Jira, Docker, Jenkins
                </p>
                
            </Card>

            <Card className="mb-5">
                <h4 className="text-lg  tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                <FaCode size={15} className="mr-1 mt-1"/>Machine Learning:
                </h4>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                PyTorch, TensorFlow, Scikit-learn, Keras, Supervised Learning, Un-supervised Learning, Reinforcement Learning
                </p>
                
            </Card>

            <Card className="mb-5">
                <h4 className="text-lg tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                <FaCode size={15} className="mr-1 mt-1"/> Operating System:
                </h4>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                Windows, Linux, MacOS
                </p>
                
            </Card>


            <Card className="mb-5">
                <h4 className="text-lg  tracking-tight text-gray-800 dark:text-white flex flex-row  md:font-normal">
                <FaCode size={15} className="mr-1 mt-1"/> Software & Tools:
                </h4>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                Maple, VSCode, Fritzing, ModelSim, CCStudio, Vivado, Multisim, TinkerCAD
                </p>
                
            </Card>

               
            
            
        </div>
    );
};
    
export default Skills;