import { FaGithub } from "react-icons/fa";
import { Table,Avatar } from 'flowbite-react';
import githubProfile from '../../img/githubprofile.png'
import { useState } from 'react';
           
const Projects = (props) => {

    const [ seeMore, setSeeMore ] = useState(true)
   

    return (

    <div className="content-wrapper py-10 xl:ml-28 ml-2 md:ml-6 lg:ml-6 ">
                
        <div className="px-1 py-2 md:ml-6 lg:ml-6 xl:ml-0 font-normal font-mono">
            <div className=" text-md ">
                Project Status Code:
            </div>
            <div className="flex flex-wrap gap-2 py-1 font-normal font-mono">
                <div className="bg-green-400 rounded-full w-5 h-5"></div>  
                <p> On going</p>        
            </div>

            <div className="flex flex-wrap gap-2 py-1 font-normal font-mono">
                <div className="bg-red-500 rounded-full w-5 h-5"></div>  
                <p> Completed/Depreciated</p>          
            </div>

            <div className="flex flex-wrap gap-2 py-1 font-normal font-mono" >
                <div className="bg-yellow-300 rounded-full w-5 h-5"></div>    
                <p> Paused</p>        
            </div>
            <div className="flex flex-wrap gap-2 py-1 mb-5 mt-2 font-normal font-mono">  
                <p> Featured Projects</p>        
            </div>
        </div>

        <div className="md:hidden font-normal font-mono">
            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-green-400 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Audio_Transformer_Arch"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> Acoustic Sound Classification - Audio Transformer</p>
                <a href="https://github.com/qtvo93/Audio_Transformer_Arch/blob/main/README.md" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-lg font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/web-dev-app-PERN/blob/main/"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> PERN stack To-do Web Application</p>
                <a href="https://github.com/qtvo93/web-dev-app-PERN/blob/main/README.md" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Machine_Learning_General/tree/main/CNN_classification/"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm">Handwritten Digits Classification - Convolutional Neural Network</p>
                <a href="https://github.com/qtvo93/Machine_Learning_General/tree/main/CNN_classification/README.md" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Machine_Learning_General/tree/main/Auto_encoder/"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> Image Noises Removal - CNN Denoising Autoencoder</p>
                <a href="https://github.com/qtvo93/Machine_Learning_General/tree/main/Auto_encoder/README.md" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

        {/* SEE MORE */}
        <label> 
        <div className="collapse text-sm -ml-4 -mt-3 font-mono">
        
        <input type="checkbox" /> 
        <div className="collapse-title text-md w-40 hover:text-pink-500 hover:animate-pulse font-mono text-gray-600 dark:text-white ml-1 -mt-2 -mb-3" onClick={()=>setSeeMore(!seeMore)}>
            {seeMore?"...see more":"...see less"}
        </div>
    
        <div className="collapse-content wrapper -mt-2"> 

            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-yellow-300 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Guess_The_Number_Game_WebApp/"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> Guess the Mystery Number Game Web Application</p>
                <a href="https://github.com/qtvo93/Guess_The_Number_Game_WebApp/blob/main/README.md" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/RISC-V/"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> RISC-V Pipeline CPU Simulation</p>
                <a href="https://github.com/qtvo93/RISC-V/blob/main/README.md" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            
            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Machine_Learning_General/blob/main/Multi-Armed-Bandit/HW2_Kelly_QuocVo.pdf"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> Multi-Armed Bandit Simulations</p>
                <a href="https://github.com/qtvo93/Machine_Learning_General/blob/main/Multi-Armed-Bandit/HW2_Kelly_QuocVo.pdf" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            
        <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Machine_Learning_General/blob/main/branch_predictor/branch_predictor.c"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm">  Instruction Branch Predictor - C </p>
                <a href="https://github.com/qtvo93/Machine_Learning_General/blob/main/branch_predictor/branch_predictor.c" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>
            
            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://engr113group11.wordpress.com/blog-2/"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> Autonomous Gardening System </p>
                <a href="https://engr113group11.wordpress.com/" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>

            <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-5">       
                <div>
                    <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white flex flex-row justify-left">
                        <div className="flex flex-wrap gap-2 mr-5">
                            <div className="bg-red-500 rounded-full w-5 h-5"></div>
                        </div>
                        <a
                        href="https://github.com/qtvo93/Microcontroller_scripts/tree/main/Arduino_Mega"
                        className="link mr-5 "
                        target="_blank" rel="noopener noreferrer"
                        >
                            <FaGithub size={22}/>
                        </a>
                        <div className="flex flex-wrap gap-2">
                            <a href="https://github.com/qtvo93" target="_blank" rel="noopener noreferrer" className="-mt-2"
                        >
                            <Avatar
                                img={githubProfile}
                                rounded={true}
                                bordered={true}
                            />
                        </a>
                                
                        </div>
                    </h5>
                </div>
                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 text-sm"> Arduino Virtual Automotive Sensory System </p>
                <a href="https://github.com/qtvo93/Microcontroller_scripts/blob/main/Arduino_Mega/QuocVo_LAB6(final).pdf" class="inline-flex items-center text-teal-500 hover:underline"   target="_blank" rel="noopener noreferrer">  
                        README.md
                    <svg class="w-5 h-5 ml-2 text-teal-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z"></path><path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z"></path></svg>
                </a>
            </div>
        
            </div>
        
        </div>

        </label> 
            
        </div>


        
        <div className='hidden md:flex font-normal font-mono'>

        <Table hoverable={true} >
        <Table.Head >
            <Table.HeadCell>
            Project name
            </Table.HeadCell>
            <Table.HeadCell>
            Status
            </Table.HeadCell>
            <Table.HeadCell>
            Owner
            </Table.HeadCell>
            <Table.HeadCell>
            Repository
            </Table.HeadCell>
            <Table.HeadCell>
            Descriptions
            </Table.HeadCell>
            {/* <Table.HeadCell>
            <span className="sr-only">
                Edit
            </span>
            </Table.HeadCell> */}
        </Table.Head>
        <Table.Body className="divide-y">

        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                Acoustic Sound Classification - Audio Transformer
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-green-400 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Audio_Transformer_Arch"
                className="link  "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Audio_Transformer_Arch/blob/main/README.md"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                PERN stack To-do Web Application
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/web-dev-app-PERN"
                className="link  "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/web-dev-app-PERN/blob/main/README.md"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            Handwritten Digits Classification - Convolutional Neural Network 
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Machine_Learning_General/tree/main/CNN_classification"
                className="link"
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Machine_Learning_General/blob/main/CNN_classification/README.md"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>
            
            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            Image Noises Removal - CNN Denoising Autoencoder 
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Machine_Learning_General/tree/main/Auto_encoder"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Machine_Learning_General/tree/main/Auto_encoder/README.md"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            Guess the Mystery Number Game Web Application
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-yellow-300 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Guess_The_Number_Game_WebApp"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Guess_The_Number_Game_WebApp/blob/main/README.md"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            RISC-V Pipeline CPU Simulation
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/RISC-V"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/RISC-V/blob/main/README.md"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
             Multi-Armed Bandit Simulations
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Machine_Learning_General/blob/main/Multi-Armed-Bandit/HW2_Kelly_QuocVo.pdf"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Machine_Learning_General/blob/main/Multi-Armed-Bandit/HW2_Kelly_QuocVo.pdf"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            Instruction Branch Predictor - C 
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Machine_Learning_General/blob/main/branch_predictor/branch_predictor.c/"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Machine_Learning_General/blob/main/branch_predictor/branch_predictor.c/"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            Autonomous Gardening System
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://engr113group11.wordpress.com/blog-2/"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://engr113group11.wordpress.com/"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            Arduino Virtual Automotive Sensory System
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
                
                
                <div className="bg-red-500 rounded-full w-5 h-5"></div>
                 
                
            </div>
            </Table.Cell>
            <Table.Cell>
            <div className="flex flex-wrap gap-2">
            <a
                href="https://github.com/qtvo93"
                target="_blank" rel="noopener noreferrer"
            >
                <Avatar
                    img={githubProfile}
                    rounded={true}
                    bordered={true}
               />
            </a>
            </div>
            </Table.Cell>
            <Table.Cell>
            <a
                href="https://github.com/qtvo93/Microcontroller_scripts/tree/main/Arduino_Mega"
                className="link "
                target="_blank" rel="noopener noreferrer"
                >
                <FaGithub size={22}/>
                </a>
            </Table.Cell>
            <Table.Cell>
                <a
                href="https://github.com/qtvo93/Microcontroller_scripts/blob/main/Arduino_Mega/QuocVo_LAB6(final).pdf"
                className="link text-teal-500"
                target="_blank" rel="noopener noreferrer"
                >
                README.md
                </a>
            </Table.Cell>
            </Table.Row>

            </Table.Body>
            </Table>

        </div>

    </div>
    );
};



export default Projects;