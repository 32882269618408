import BMSImage from '../../img/bms.jpg';

const BmsExp = (props) => {
    return (
        <div className="content-wrapper -ml-4">
             {/* <Button color="light" pill={true} size="sm" onClick={()=>{props.setContent("Experience")}} className="mb-5 -ml-4 hover:text-pink-500">
                Back
                <HiArrowNarrowLeft className="ml-2 h-3 w-3" />
            </Button> */}
        <div>
        <p className="">Princeton, NJ</p>
        <div className="group ">
            <a href="https://bms.com/"  target="_blank" rel="noopener noreferrer" >
                <img src={BMSImage} className="rounded-xl w-16" alt="BMSLogo"></img>
            </a>
            <span className="job-tooltip md:group-hover:scale-100 z-50">
                Bristol Myers Squibb
            </span>
        </div>
        </div>
       
        <h2 className="text-md mb-1 font-semibold">DevOps and Cloud Engineering Team</h2>
        <ul className="list-disc ml-4">
  
            
        </ul>
        <p className=""><span className="underline font-bold">Utilized:</span> Node.js, React.js, Python, SVN, AWS Lambda, CloudFormation, SQS, S3, IAM, RestAPI, SNS, EventBridge</p>
        </div>
    );
};



export default BmsExp;