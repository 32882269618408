import { useEffect, useState } from 'react';

const UseLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.log(error)
    }
  };
  return [storedValue, setValue]
};

const UseDarkMode = () => {
  const [enabled, setEnabled] = UseLocalStorage('dark-theme')
  // const [enabled, setEnabled] = UseLocalStorage('')
  // const isEnabled = typeof enabledState === 'undefined' && enabled
  // const isEnabled = enabled

  useEffect(() => {
    const className = 'dark'
    const bodyClass = window.document.body.classList

    enabled ? bodyClass.add(className) : bodyClass.remove(className)
  }, [enabled])

  return [enabled, setEnabled]
}

export default UseDarkMode;