
const Education = (props) => {
    return (
        <div className="content-wrapper text-center md:w-full mt-16 mb-6" ref={props.eduRef}>

       
            <div className="mockup-code text-left w-auto  md:ml-16 md:mr-6 -ml-1">
                <pre data-prefix="$" className="text-warning "><code>ls edu/</code></pre> 
                <pre data-prefix=" " className=""><code></code></pre>

                <pre data-prefix=">" className="md:text-lg md:-ml-[2px] text-accent "><code><a href="https://drexel.edu" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Master of Science, Computer<span className='block ml-10  md:mb-0 md:ml-0  md:inline-flex'> Engineering</span></a></code></pre> 
                <pre data-prefix=" " className=""><code>Drexel University </code></pre> 
                {/* <pre data-prefix=" " className="text-gray-400"><code>Computer Engineering,<span className='block ml-10  md:mb-0 md:ml-0  md:inline-flex'> June 2023</span></code></pre>  */}
                {/* <pre data-prefix=" " className=""><code></code></pre> */}

                <pre data-prefix=" " className=""><code></code></pre>
                <pre data-prefix=">" className="md:text-lg md:-ml-[2px] text-accent "><code><a href="https://drexel.edu" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Bachelor of Science, Computer<span className='block ml-10  md:mb-0 md:ml-0  md:inline-flex'> Engineering</span></a></code></pre> 
                <pre data-prefix=" " className="text-gray-400"><code>Mathematics Minor</code></pre> 
                <pre data-prefix=" " className=""><code>Drexel University </code></pre> 
                
                {/* <pre data-prefix=" " className=""><code>Mathematics Minor</code></pre>  */}
                {/* <pre data-prefix=" " className=""><code>2019 - 2022</code></pre> */}

                <pre data-prefix=" " className=""><code></code></pre>
                <pre data-prefix=">" className="md:text-lg md:-ml-[2px] text-accent"><code><a href="https://camdencc.edu" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Math<span className='hidden md:inline-flex '>ematics</span> and Computer Science</a></code></pre> 
                <pre data-prefix=" " className=""><code>Camden County College</code></pre> 
                {/* <pre data-prefix=" " className=""><code>2018 - 2019</code></pre> */}

                <pre data-prefix=" " className=""><code></code></pre>
                <pre data-prefix=">" className="md:text-lg md:-ml-[2px] text-accent"><code><a href="https://dccc.edu" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Electrical Engineering </a></code></pre> 
                <pre data-prefix=" " className=""><code>Delaware County Community<span className='block ml-10 -mb-6 md:mb-0 md:ml-0  md:inline-flex'> College</span></code></pre> 
                {/* <pre data-prefix=" " className=""><code>2014 - 2016</code></pre> */}

                <pre data-prefix=" " className="mt-5 md:mt-0"><code></code></pre>
                <pre data-prefix=">" className="md:text-lg md:-ml-[2px] text-accent" ><code><a href="https://www.linkedin.com/school/lhpvietnam/?originalSubdomain=vn" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300 '><span className='md:mr-0 mr-3'>Highschool Diploma </span></a></code></pre> 
                <pre data-prefix=" " className=""><code>Le Hong Phong Highschool for<span className='block ml-10 -mb-6 md:mb-0 md:ml-0 md:inline-flex'> The Gifted, HCMC, Vietnam</span> </code></pre> 
                {/* <pre data-prefix=" " className=""><code>2014 - 2016</code></pre> */}
            
            </div>

    
            
        {/* <div className="md:px-40 md:mr-32 md:ml-28">

            

            <div className="degree-border hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly ">
                    <div className="mr-1"><GR.GrCertificate size={30}/></div>
                    <strong className="mr-1 text-teal-500">Drexel University</strong> 
                    <div><a href="https://drexel.edu" target="_blank" rel="noopener noreferrer">
                        <img src={DrexelImage} className="rounded-xl w-9"></img>
                    </a></div></div>
                <div className="text-lg"><strong>Master of Science</strong></div>
                <div><p>Computer Engineering</p></div>
                <div><small>2021 - 2023</small></div>
            </div>

            <div className="degree-border hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly">
                    <div className="mr-1"><GR.GrCertificate size={30}/></div>
                    <strong className="mr-1 text-teal-500">Drexel University</strong> 
                    <div><a href="https://drexel.edu" target="_blank" rel="noopener noreferrer">
                        <img src={DrexelImage} className="rounded-xl w-9"></img>
                    </a></div></div>
                <div className="text-lg"><strong>Bachelor of Science</strong></div>
                <div><p>Computer Engineering</p></div>
                <div><small>2019 - 2022</small></div>
            </div>

            <div className="degree-border  hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly">
                    <div className="mr-1"><GR.GrCertificate size={30}/></div>
                    <strong className="mr-1 text-teal-500">Camden County College</strong> 
                    <div><a href="https://camdencc.edu" target="_blank" rel="noopener noreferrer">
                        <img src={CamdenImage} className="rounded-xl w-9"></img>
                    </a></div></div>
      
                <div><p>Mathematics and Computer Science</p></div>
                <div><small>2018 - 2019</small></div>
            </div>

            <div className="degree-border  hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly">
                    <div className="mr-1"><GR.GrCertificate size={30}/></div>
                    <strong className="mr-1 text-teal-500">Delaware County Community College</strong> 
                    <div><a href="https://dccc.edu" target="_blank" rel="noopener noreferrer">
                        <img src={DCCCImage} className="rounded-xl w-12  md:w-9"></img>
                    </a></div></div>
       
                <div><p>Electrical Engineering</p></div>
                <div><small>2014 - 2016</small></div>
            </div>

            <div className="degree-border  hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl md:text-2xl text-center items-center content-evenly justify-evenly">
                    <div className="mr-1"><GR.GrCertificate size={30}/></div>
                    <strong className="mr-1 text-teal-500">Le Hong Phong Highschool For The Gifted</strong> 
                    <div><a href="https://www.linkedin.com/school/lhpvietnam/?originalSubdomain=vn" target="_blank" rel="noopener noreferrer">
                        <img src={LHPImage} className="rounded-xl w-16 md:w-9"></img>
                    </a></div></div>
                <div className="text-lg"><strong>Highschool Diploma</strong></div>
                <div><p>Natural Science Concentration</p></div>
                <div><small></small></div>
            </div>
         
            </div> */}

        </div>

    );
};

// const HoverIcon = ({ icon, text }) => (
//     <div className="sidebar-icon group">
//       {icon}
//       <span class="sidebar-tooltip group-hover:scale-100">
//         {text}
//       </span>
//     </div>
//   );
    
export default Education;