import RokuImage from '../../img/roku.jpg';

           
const RokuPartTimeExp = (props) => {
    return (
        <div className="content-wrapper -ml-4">
             {/* <Button color="light" pill={true} size="sm" onClick={()=>{props.setContent("Experience")}} className="mb-5 -ml-4 hover:text-pink-500">
                Back
                <HiArrowNarrowLeft className="ml-2 h-3 w-3" />
            </Button> */}
        <div>
        <p className="">Boston, MA </p>
        <div className="group my-2">
            <a href="https://roku.com/"  target="_blank" rel="noopener noreferrer">
                <img src={RokuImage} className="rounded-xl w-12" alt="RokuLogoImage"></img>
            </a>
            <span className="job-tooltip md:group-hover:scale-100 z-50">
                Roku Inc.
            </span>
        </div>
        </div>
       
        <h2 className="text-md mb-1 font-semibold">Cloud Technology Infrastructure Team</h2>
 
        <ul className="list-disc ml-4">

            
        </ul>
        <p className=""><span className="underline font-bold">Utilized:</span> Object-Oriented Programming, Python, AWS, Git, Grafana, Terraform, Terragrunt, Gitlab, Kubernetes, Docker, CI/CD, Bash, Data Structures and Algorithms, Jenkins, YAML, JSON, GraphQL, Rest APIs</p>
        </div>

    );
};


export default RokuPartTimeExp;