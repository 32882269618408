import * as ALL from '../PagesContent/'
import TopNav from '../TopNavBar/TopNavBar.js';
import Footer from '../Footer/Footer.js';
import { FaHashtag,FaGithubAlt,FaCode } from 'react-icons/fa';

const ContentContainer = (props) => {
 
  return (
    <div className="block  dark:text-white">
    <div className='mb-[64px]'>
        <TopNav className="sticky top-0"/>
    </div>
    <div className='content-container'>
        {/* <div className='content-list'> */}
        
          {/* {props.content==="Introductions"?<ALL.Intro/>:null}
          {props.content==="Skills"?<ALL.Skills/>:null}
          {props.content==="Experience"?<ALL.Experience setContent={props.setContent}/>:null}
          {props.content==="Educations"?<ALL.Education/>:null}
          {props.content==="Certifications"?<ALL.Certifications/>:null} */}
          {/* <div><a href="https://dccc.edu"><img src={Logo} alt="logo" className='w-12 h-auto mt-8 ml-7 md:ml-20 -mb-10 z-40'></img></a></div> */}
          
          <Divider sectionName={"Welcome"} innerRef={props.introRef} />
          <div className="md:mt-1 md:mb-6">
          <ALL.Intro />
          {/* <DividerLine />  */}
          <div className="divider md:mx-80 mx-9 opacity-40 md:-mt-[10px] shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div>
          </div>

          <div className='bg-teal-200 dark:bg-gray-600 bg-opacity-10'>
          <Divider sectionName={"Experience"} innerRef={props.expRef} />
          <ALL.Experience setContent={props.setContent} />
          {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
          </div>

          <Divider sectionName={"Education"} innerRef={props.eduRef}/>
          <ALL.Education/>
          {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}

          <div className='bg-teal-200 dark:bg-gray-600 bg-opacity-10 mt-5'>
          <Divider sectionName={"Certifications"} innerRef={props.certsRef}/>
          <ALL.Certifications/>
          {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
          </div>

          <Divider sectionName={"Curriculum"} innerRef={props.curriculumRef}/>
          <ALL.Curriculum/>
          {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}

          <div className='bg-teal-200 dark:bg-gray-600 bg-opacity-10 mt-5'>
          <Divider sectionName={"Technical Skills"} innerRef={props.skillsRef}/>
          <ALL.Skills/>
          <div className="mb-6"></div>
          {/* <div className="divider md:mx-80 mx-9 opacity-40 -mb-5 shadow-xs"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div> */}
          </div>

          <Divider sectionName={"Projects"} innerRef={props.projectsRef}/>
          <ALL.Projects/>
          <div className="divider md:mx-80 mx-9 opacity-40 shadow-xs -mt-12 mb-2 md:mt-0"><FaCode size={25}/><FaGithubAlt size={25}/><FaCode size={25}/></div>
          <div className="mb-6"></div>
  
     
    </div>
    <div>
         <Footer />
    </div>
    </div>
  );
};

const Divider = (props) =>  {
  return (
    
  <div ref={props.innerRef}>
  <div  id={props.sectionName} className="ml-5 mt-12 -mb-7 pl-2 font-semibold font-mono flex flex-row md:ml-16 md:text-2xl md:text-bold md:mt-5" >
    <FaHashtag size={14} className="mt-1 mr-1 md:mt-2 md:mr-1"/>
    <h2>{props.sectionName}</h2> 
  </div>
  </div>
  )
}

// const DividerLine = (props) => {
//   const [time , setTime] = useState(0)
//   useEffect(() => {
//     const interval = setInterval(() => {
      
//       setTime(currentCount => {
//         return currentCount +1 
//       })
//     }, 2000);
    
//     return () => clearInterval(interval);
//   }, []);

//   return(
//     <div className='flex flex-row mt-3 -mb-5 text-xs'>
//     {/* <hr className={`sidebar-hr ${time%2===0?"translate-x-52":"-translate-x-72"}`} /> */}
//     <p className={`md:ml-52 ml-10 ` }> ... ... ... </p>
//     <p className={`md:ml-20 ml-10 `}> ... ... ... </p>
//     <p className={`md:ml-20 ml-10 `}> ... ... ... </p>
//     <p className={`md:ml-20 ml-10 `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
//     <p className={`md:ml-20 md:block hidden `}> ... ... ... </p>
  
//     </div>
//   )}

export default ContentContainer;