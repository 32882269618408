import DCCCImage from '../../img/dccc.jpg';
           
const DcccExp = (props) => {
    return (

        <div className="content-wrapper -ml-4">
             {/* <Button color="light" pill={true} size="sm" onClick={()=>{props.setContent("Experience")}} className="mb-5 -ml-4 hover:text-pink-500">
                Back
                <HiArrowNarrowLeft className="ml-2 h-3 w-3" />
            </Button> */}
        <div>
        <p className="">Media, PA</p>
        <div className="group ">
            <a href="https://dccc.edu"  target="_blank" rel="noopener noreferrer" >
                <img src={DCCCImage} className="rounded-xl w-12" alt="DCCCLogo"></img>
            </a>
            <span className="job-tooltip md:group-hover:scale-100 z-50">
                Delaware County Community College
            </span>
        </div>
        </div>
       
        <h2 className="text-md mb-1 font-semibold">Academic Tutoring Center Team</h2>
        <ul className="list-disc ml-4">
        <ul className="list-disc ml-3">
            {/* <li className="mb-2">Tutored Calculus, Pre-calculus, and Algebra to peers</li>
            <li className="mb-2">Kept track of progress and reported to the Tutoring Center Cordinator</li>
            <li className="mb-2">Developed learning plan and strategized learning methodologies to better assist peers in making progress</li> */}

        </ul>
            
        </ul>
        <p className=""><span className="underline font-bold">Utilized:</span> Communication Skills, Tutoring Skills, Mathematics, Illustration Skills, Organizational Skills, Analytic Skills</p>
        </div>

       
    );
};



export default DcccExp;