
const Certifications = (props) => {
    return (

        <div className="content-wrapper-odd text-center md:w-full mt-16 mb-10" ref={props.certsRef}>

            <div className="mockup-code text-left w-auto md:ml-16 md:mr-6 -ml-2">  
                <pre data-prefix="$" className="text-warning"><code>ls certs/</code></pre> 
                <pre data-prefix=" " className=""><code></code></pre>
                <pre data-prefix=">" className="md:text-lg md:-ml-[2px] text-accent"><code><a href="https://www.credly.com/badges/3d100d72-42d7-4076-93cb-6e286c8261fe/public_url" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>HashiCorp Certified: Terraform <span className='block ml-12 md:mb-0 md:ml-0  md:inline-flex'>Associate</span></a></code></pre> 
                <pre data-prefix=" " className=""><code>HashiCorp</code></pre> 
                <pre data-prefix=" " className="text-gray-400"><code>September 2023</code></pre> 
                {/* <pre data-prefix=" " className=""><code></code></pre> */}

                <pre data-prefix=">" className="md:text-lg md:-ml-[2px]  mt-5 text-accent"><code><a href="https://www.credly.com/badges/0e9b1303-c3ac-42db-a3a3-6958f0e0157a/public_url" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Certified Solutions Architect <span className='block ml-12 md:mb-0 md:ml-0  md:inline-flex'>- Associate</span></a></code></pre> 
                <pre data-prefix=" " className=""><code>AWS Training and Certification</code></pre> 
                <pre data-prefix=" " className="text-gray-400"><code>October 2021</code></pre> 
                {/* <pre data-prefix=" " className=""><code></code></pre> */}

                <pre data-prefix=">" className="md:text-lg md:-ml-[2px]  mt-5 text-accent "><code><a href="https://www.credly.com/badges/1ab2225e-a8e4-416b-aa11-2711de4ee9b5/public_url" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Certified Cloud Practitioner </a></code></pre> 
                <pre data-prefix=" " className=""><code>AWS Training and Certification</code></pre> 
                <pre data-prefix=" " className="text-gray-400"><code>July 2021</code></pre> 
                {/* <pre data-prefix=" " className=""><code></code></pre> */}

                <pre data-prefix=">" className="md:text-lg md:-ml-[2px]  mt-5 text-accent"><code><a href="https://coursera.org/share/fab982da7e2144334aace52e4ec9d264" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>AWS Fundamentals: Building <span className='block ml-[50px] md:mb-0 md:ml-0 md:inline-flex'>Serverless Applications</span></a></code></pre> 
                <pre data-prefix=" " className=" "><code>Coursera E-learning </code></pre> 
                <pre data-prefix=" " className="text-gray-400"><code>September 2020</code></pre> 
                {/* <pre data-prefix=" " className=""><code></code></pre> */}

                <pre data-prefix=">" className="md:text-lg md:-ml-[2px]  mt-5 text-accent"><code><a href="https://university.atlassian.com/student/award/DgtVkwp7ucGPrwqRNZRjKCBY" target="_blank" rel="noopener noreferrer" className=' hover:text-yellow-300'>Jira Fundamentals Badge</a></code></pre> 
                <pre data-prefix=" " className=" "><code>Atlassian University</code></pre> 
                <pre data-prefix=" " className="text-gray-400"><code>March 2022</code></pre> 
                {/* <pre data-prefix=" " className=""><code></code></pre> */}
            
            </div>

        {/* <div className="md:pr-96 md:mr-32 md:ml-28 ">
            
            <div className="degree-border hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl md:text-2xl text-center items-center content-evenly justify-evenly ">
                
                    <div className="-ml-2 -mr-2 -mt-2 md:mx-5"><a href="https://www.credly.com/badges/0e9b1303-c3ac-42db-a3a3-6958f0e0157a/public_url" target="_blank" rel="noopener noreferrer">
                        <img src={AWS} className="rounded-xl w-9"></img>
                    </a>
                    </div>
                 
                    <strong className="-ml-2 -mr-2 mb-3 text-teal-500">AWS Training and Certification</strong> 
                    <div className="-ml-2 -mr-2 -mt-2 md:mx-5"><a href="https://www.credly.com/badges/0e9b1303-c3ac-42db-a3a3-6958f0e0157a/public_url" target="_blank" rel="noopener noreferrer">
                        <img src={SAA} className="rounded-xl w-9"></img>
                    </a></div>
                </div>
                <div className="text-lg"><strong>AWS Certified Solutions Architect - Associate</strong></div>
                <div><p>October 2021</p></div>
          
            </div>

            <div className="degree-border hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly ">
              
                    <div className="-ml-2 -mr-2 -mt-2 md:mx-5"><a href="https://www.credly.com/badges/1ab2225e-a8e4-416b-aa11-2711de4ee9b5/public_url" target="_blank" rel="noopener noreferrer">
                        <img src={AWS} className="rounded-xl w-9"></img>
                    </a></div>
                 
                    <strong className="-ml-2 -mr-2 mb-3 text-teal-500">AWS Training and Certification</strong> 
                    <div className="-ml-2 -mr-2 -mt-2  md:mx-5"><a href="https://www.credly.com/badges/1ab2225e-a8e4-416b-aa11-2711de4ee9b5/public_url" target="_blank" rel="noopener noreferrer">
                        <img src={CCP} className="rounded-xl w-9"></img>
                    </a></div>
                </div>
                <div className="text-lg"><strong>AWS Certified Cloud Practitioner</strong></div>
                <div><p>July 2021</p></div>
         
            </div>

            <div className="degree-border hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly ">
                    <div  className="-ml-5  -mt-2"><GR.GrCertificate size={24}/></div>
            
                    <strong className="px-1 mb-3 text-teal-500">Coursera E-Learning</strong> 
                    <div className="-mr-5 -mt-2"><a href="https://coursera.org/share/fab982da7e2144334aace52e4ec9d264" target="_blank" rel="noopener noreferrer">
                        <img src={Coursera} className="rounded-xl w-6"></img>
                    </a></div>
                </div>
                <div className="text-lg"><strong>AWS Fundamentals: Building Serverless Applications</strong></div>
                <div><p>September 2020</p></div>
               
            </div>

            <div className="degree-border hover:bg-teal-100 hover:bg-opacity-50 bg-white shadow-2xl">
                <div className="flex flex-row text-xl  md:text-2xl text-center items-center content-evenly justify-evenly ">
                    <div  className="-ml-5  -mt-2"><GR.GrCertificate size={24}/></div>
            
                    <strong className="px-1 mb-3 text-teal-500">Atlassian University</strong> 
                    <div className="-mr-5 -mt-2"><a href="https://university.atlassian.com/student/award/DgtVkwp7ucGPrwqRNZRjKCBY" target="_blank" rel="noopener noreferrer">
                        <img src={Jira} className="rounded-xl w-6"></img>
                    </a></div>
                </div>
                <div className="text-lg"><strong>Jira Fundamentals Badge</strong></div>
                <div><p>March 2022</p></div>
          
            </div>
        
        
        </div> */}
        </div>
        
    );
};

export default Certifications;