import DrexelImage from '../../img/drexel.jpg';
import React from 'react';
           
const IEEEexp = (props) => {
    return (
        <div className="content-wrapper -ml-4">
             {/* <Button color="light" pill={true} size="sm" onClick={()=>{props.setContent("Experience")}} className="mb-5 -ml-4 hover:text-pink-500">
                Back
                <HiArrowNarrowLeft className="ml-2 h-3 w-3" />
            </Button> */}
        <div>
        <p className="">Philadelphia, PA</p>
        <div className="group my-2">
            <a href="https://research.coe.drexel.edu/ece/imaple/"  target="_blank" rel="noopener noreferrer" >
                <img src={DrexelImage} className="rounded-xl w-12" alt="impaleLogo"></img>
            </a>
            <span className="job-tooltip md:group-hover:scale-100 z-50">
            Drexel University - College of Engineering, iMaPLe Lab
            </span>
        </div>
        </div>
       
        <h2 className="text-md mb-1 font-semibold">IEEE-HKN Drexel Beta Alpha Chapter</h2>

        <p className=""><span className="underline font-bold">Utilized:</span> Leadership Skills, Communication Skills, Organizational Skills </p>
        </div>

    );
};



export default IEEEexp;