import { Timeline } from 'flowbite-react';
import React, { useState } from 'react';

// import { HiCalendar,HiArrowNarrowRight } from "react-icons/hi";
// import DrexelImage from '../../img/drexel.jpg';
// import RokuImage from '../../img/roku.jpg';
// import { HiArrowNarrowLeft } from "react-icons/hi";
// import { FaRegObjectUngroup } from 'react-icons/fa';

import * as EXP from './index.js';

const Exprience = (props) => {

    const [ seeMore, setSeeMore ] = useState(true)
   
    return (
    <div className='md:flex md:flex-row '>
        <div className="content-wrapper-odd mt-10 md:mt-12 md:pl-40 lg:pl-40 pt-5 md:w-full xl:pl-96 ml-1" >
        {/* Use imported Item */}
        <Timeline >

        {/* ROKU */}
        <Timeline.Item>
            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content>

            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent">June 2023 - Present</div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer 
            </Timeline.Title>
            <Timeline.Body>
                <small>Full-time</small>
                <div className='font-semibold'> Roku Inc.</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.RokuPartTimeExp/>}
                </div>
                </div>
                </label>
     
            </Timeline.Body>

            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent -mt-5">May 2023 - June 2023</div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer 
            </Timeline.Title>
            <Timeline.Body>
                <small>Part-time</small>
                <div className='font-semibold'> Roku Inc.</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.RokuPartTimeExp/>}
                </div>
                </div>
                </label>
     
            </Timeline.Body>

            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent -mt-5">June 2022 - September 2022 </div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer Intern
            </Timeline.Title>
            <Timeline.Body>
                <small>Full-time Internship</small>
                <div className='font-semibold'> Roku Inc.</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.RokuInternExp/>}
                </div>
                </div>
                </label>
     
            </Timeline.Body>
        
            </Timeline.Content>
        </Timeline.Item>
            

        
        {/* <Timeline.Item>
            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content>
            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent">June 2022 - September 2022 </div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer Intern
            </Timeline.Title>
            <Timeline.Body>
                <small>Full-time Internship</small>
                <div className='font-semibold'> Roku Inc.</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.RokuInternExp/>}
                </div>
                </div>
                </label>
     
            </Timeline.Body>
        
            </Timeline.Content>
        </Timeline.Item> */}

        </Timeline>
        
        {/* SEE MORE */}
        <label> 
        <div className="collapse text-sm -ml-4 -mt-3 font-mono">
        
        <input type="checkbox" /> 
        <div className="collapse-title text-md w-40 hover:text-pink-500 hover:animate-pulse font-mono text-gray-600 dark:text-white -mt-5 ml-7 -mb-2" onClick={()=>setSeeMore(!seeMore)}>
            {seeMore?"...see more":"...see less"}
        </div>
    
        <div className="collapse-content wrapper -mt-2"> 
            
        <Timeline>
                    {/* DREXEL EXP */}
        <Timeline.Item >
            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content> 
            <Timeline.Time>
                <div className="text-teal-500 dark:text-accent">September 2022 - Present </div>
            </Timeline.Time>
            <Timeline.Title>
                Research Assistant - Machine Learning, iMaPLe Lab
            </Timeline.Title>
            <Timeline.Body>

                <small>Part-time</small>
                <div className='font-semibold'>Drexel University - College of Engineering</div>  

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.ImapleExp/>}
                </div>
                </div>
                </label> 

            </Timeline.Body>

            <Timeline.Time>
                <div className="text-teal-500 dark:text-accent -mt-5">May 2022 - June 2023</div>
            </Timeline.Time>
            <Timeline.Title>
                Vice President - IEEE-HKN, Beta Alpha Chapter
            </Timeline.Title>
            <Timeline.Body>

                <small>Part-time</small>
                <div className='font-semibold'>Drexel University - College of Engineering</div>  

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.IEEEexp/>}
                </div>
                </div>
                </label> 

            </Timeline.Body>

            </Timeline.Content>
        </Timeline.Item>

        {/* MOBERG ANALYTICS */}
        <Timeline.Item>
            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content>
            <Timeline.Time>
                <div className="text-teal-500 dark:text-accent">September 2022 - June 2023</div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer
            </Timeline.Title>
            <Timeline.Body>
                <small>Part-time</small>
                <div className='font-semibold'> Moberg Analytics</div>  

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.MobergExp/>}
                </div>
                </div>
                </label> 
            </Timeline.Body>

            <Timeline.Time>
                <div className="text-teal-500 dark:text-accent -mt-5">March 2022 - June 2022</div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer Co-op
            </Timeline.Title>
            <Timeline.Body>
                <small>Full-time Internship</small>
                <div className='font-semibold'> Moberg Analytics</div>  

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.MobergCoopExp/>}
                </div>
                </div>
                </label>
            </Timeline.Body>

            </Timeline.Content>

        </Timeline.Item>
        
        {/* <Timeline.Item>
            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content>
            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent">March 2022 - June 2022</div>
            </Timeline.Time>
            <Timeline.Title>
                Software Engineer Co-op
            </Timeline.Title>
            <Timeline.Body>
                <small>Full-time Internship</small>
                <div className='font-semibold'> Moberg Analytics</div>  

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.MobergCoopExp/>}
                </div>
                </div>
                </label>
            </Timeline.Body>
            
            </Timeline.Content>
        </Timeline.Item> */}

        <Timeline.Item >
       
            {/* <Timeline.Point className="hover:animate-pulse"/>
             */}

            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
        
             
            <Timeline.Content>
            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent">March 2021 - September 2021</div>
            </Timeline.Time>
            <Timeline.Title>
                Cloud Developer Co-op
            </Timeline.Title>
            <Timeline.Body>
                <small>Full-time Internship</small>
                <div className="font-semibold">Bristol Myers Squibb</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.BmsExp/>}
                </div>
                
                </div>
            </label> 
            </Timeline.Body>
            

            {/* <Button color="light" pill={true} size="xs" onClick={()=>{props.setContent("BmsExp")}} className="hover:text-pink-500">
                View more...
                <HiArrowNarrowRight className="ml-2 h-3 w-3" />
            </Button> */}
            </Timeline.Content>

           
        </Timeline.Item>
       
        
      

        <Timeline.Item>
             <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content>
            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent">August 2018 - May 2019</div>
            </Timeline.Time>
            <Timeline.Title>
                Office Assistant
            </Timeline.Title>
            <Timeline.Body>
                <small>Part-time Internship</small>
                <div className="font-semibold">Presidential Insurance Agency</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.PIAgencyExp/>}
                </div>
                </div>
                </label>   
            </Timeline.Body>
            {/* <Button color="light" pill={true} size="xs" onClick={()=>{props.setContent("AgencyExp")}} className="hover:text-pink-500">
                View more...
                <HiArrowNarrowRight className="ml-2 h-3 w-3" />
            </Button> */}
            </Timeline.Content>
        </Timeline.Item>

        <Timeline.Item>
            <span class="absolute flex items-center justify-center w-6 h-6 bg-teal-100 rounded-full -left-3 ring-8 ring-teal-100 ring-opacity-10 dark:ring-gray-600 dark:bg-teal-100 hover:animate-pulse">
                <svg aria-hidden="true" class="w-3 h-3 text-teal-500 dark:text-accent " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
             </span>
            <Timeline.Content>
            <Timeline.Time>
            <div className="text-teal-500 dark:text-accent">December 2015 - June 2016</div>
            </Timeline.Time>
            <Timeline.Title>
                Calculus Tutor
            </Timeline.Title>
            <Timeline.Body>
                <small>Part-time</small>
                <div className="font-semibold">Delaware County Community College</div> 

                <label> 
                <div className="collapse collapse-arrow text-sm -ml-4 -mt-3 ">
                
                <input type="checkbox" /> 
                <div className="collapse-title text-sm w-20 hover:text-pink-500 hover:animate-pulse">
                    ...
                </div>
                <div className="collapse-content"> 
                    {<EXP.DcccExp/>}
                </div>
                </div>
                </label> 
            </Timeline.Body>
           
            </Timeline.Content>
        </Timeline.Item>

        </Timeline>
        
        </div>
        
        </div>

        </label> 
        
   
       
        {/* Use raw html */}
             {/*<div className='-z-50'>
                
                <ol class="relative border-l border-gray-200 dark:border-gray-700">                  
                    <li class="mb-10 ml-4">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">February 2022</time>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Application UI code in Tailwind CSS</h3>
                        <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">Get access to over 20+ pages including a dashboard layout, charts, kanban board, calendar, and pre-order E-commerce & Marketing pages.</p>
                        <a href="#" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-teal-500 dark:text-accent focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-teal-500 dark:text-accent dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">Learn more <svg class="w-3 h-3 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></a>
                    </li>
                    <li class="mb-10 ml-4">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">March 2022</time>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Marketing UI design in Figma</h3>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">All of the pages and components are first designed in Figma and we keep a parity between the two versions even as we update the project.</p>
                    </li>
                    <li class="ml-4">
                        <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                        <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">April 2022</time>
                        <h3 class="text-lg font-semibold text-gray-900 dark:text-white">E-Commerce UI code in Tailwind CSS</h3>
                        <p class="text-base font-normal text-gray-500 dark:text-gray-400">Get started with dozens of web components and interactive elements built on top of Tailwind CSS.</p>
                    </li>
                </ol>
    
                </div> */}

        </div>
        
       
    </div>
      
    );
};



export default Exprience;
