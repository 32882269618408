import SideBar from './components/SideBar/SideBar.js';
import ContentContainer from './components/ContentContainer/ContentContainer.js';

import { useEffect, useRef } from 'react';

function App() {
  const introRef = useRef()
  const eduRef = useRef()
  const expRef = useRef()
  const certsRef = useRef()
  const skillsRef = useRef()
  const curriculumRef = useRef()
  const projectsRef =useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
  return (

    <div>  
      <SideBar introRef={introRef} eduRef={eduRef} expRef={expRef} certsRef={certsRef} skillsRef={skillsRef} curriculumRef={curriculumRef} projectsRef={projectsRef}/>
    
      <ContentContainer introRef={introRef} eduRef={eduRef} expRef={expRef} certsRef={certsRef} skillsRef={skillsRef} curriculumRef={curriculumRef} projectsRef={projectsRef}/>
    </div>
    
  );
}

export default App;
