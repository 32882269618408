import * as FA from 'react-icons/fa';
import { BsFillMoonStarsFill, BsSunFill } from 'react-icons/bs';
import { SiGooglescholar } from "react-icons/si";
// import { GoUnmute } from 'react-icons/go';
import UseDarkMode from '../DarkMode/DarkMode.js';

const TopNav = (props) => {
    const [darkTheme, setDarkTheme] = UseDarkMode()

    const handleMode = () => setDarkTheme(!darkTheme)

    return (
        <div className=" md:flex md:flex-row fixed top-0 right-0 z-40">
       {/* <div className="flex items-center cursor-pointer right-12 pr-1  top-5 z-10 mr-3 md:right-5 md:top-4 hover:animate-bounce hover:text-pink-400"> */}
            <div className='top-navigation '>
                {/* <HashtagIcon /> */}
                {/* <Title content={props.content}/> */}
            {/* </div>
            <div className='top-navigation '> */}
                {/* <Search /> */}
               
                <Linkedin />
                <GitHub />
                <GoogleScholar />
                <Twitter />
                {/* <Slack /> */}
                {/* <ThemeIcon /> */}
                <div className='md:right-[100px] md:absolute md:top-5 absolute right-20 top-6 flex flex-row'>
                  <BsSunFill size='12' className='mt-1 mr-1 dark:text-gray-900'/>
                  <label class="relative inline-flex items-center mb-5 cursor-pointer">
                    <input type="checkbox" value="" class="sr-only peer" checked={darkTheme} onChange={()=>handleMode()}/>
                    <div class="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-teal-200 after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-gray-700"></div>
                  </label>
                  <BsFillMoonStarsFill size='11' className='mt-[4px] ml-1 dark:text-gray-900'/>
                </div>

        
                
                {/* <Title content={props.content}/> */}
            </div>
        </div>
    );
};

// const Search = () => (
//     <div className='search'>
//       <input className='search-input' type='text' placeholder='Search...' />
//       <FA.FaSearch size='18' className='' />
//     </div>
// );

// const Audio = () => <a href="https://www.howtopronounce.com/quoc/5104800" target="_blank" rel="noopener noreferrer"><GoUnmute size='20' className='top-navigation-icon' /> </a>;
const Linkedin = () => <a href="https://www.linkedin.com/in/qtvo93/" target="_blank" rel="noopener noreferrer"><FA.FaLinkedin size='20' className='top-navigation-icon' /> </a>;
const GitHub = () => <a href="https://github.com/qtvo93/" target="_blank" rel="noopener noreferrer"><FA.FaGithub size='20' className='top-navigation-icon'/></a>;
const GoogleScholar = () => <a href="https://scholar.google.com/citations?user=zxQLJMoAAAAJ&hl=en" target="_blank" rel="noopener noreferrer"><SiGooglescholar size='20' className='top-navigation-icon' /></a>;
const Twitter = () => <a href="https://twitter.com/qtvo_dev" target="_blank" rel="noopener noreferrer"><FA.FaTwitter size='20' className='top-navigation-icon' /> </a>;
// const Slack = () => <a href="https://join.slack.com/t/quocvodev/shared_invite/zt-1gtcmcwzl-NUgAM6GtE8Ublyq5Mvplng" target="_blank" rel="noopener noreferrer"><FA.FaSlack size='20' className='top-navigation-icon' /></a>;

// const HashtagIcon = () => <FA.FaHashtag size='18' className='title-hashtag drop-shadow-md ' />;
// const Title = (props) => <h5 className='title-text drop-shadow-md hidden md:flex md:flex-row'><FA.FaHashtag size={14} className="mt-1 mr-1 md:mt-2 md:mr-1"/><strong>{props.content}</strong></h5>;

// const ThemeIcon = () => {
//     const [darkTheme, setDarkTheme] = UseDarkMode();
//     const handleMode = () => setDarkTheme(!darkTheme);
//     return (
//       <span onClick={handleMode} className="md:right-20 md:absolute">
//         {darkTheme ? (
//           <BsSunFill size='20' className='top-navigation-icon hover:animate-none' />
//         ) : (
//           <BsFillMoonStarsFill size='20' className='top-navigation-icon hover:animate-none' />
//         )}
//       </span>
//     );
//   };




export default TopNav;